.project-card {
  width: 26%;
  /* height: 84%; */
  position: relative;
  display: flex;
  flex-direction: column;
  transition: 0.4s;
  box-shadow: 0px 0px 20px 0px #0f0f0f;
}

.project-card:hover {
  transform: scale(1.1);
}
.project-card:hover::before,
.project-card:hover::after {
  background: repeating-conic-gradient(
    from var(--a),
    rgb(243, 70, 136),
    #0ff,
    rgb(204, 0, 255),
    #0ff
  );
  content: "";
  inset: 0;
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  z-index: -2;
  filter: blur(30px);
  animation: rotate 4s linear infinite;
}

.project-card img {
  width: 100%;
}
.project-card .content {
  background: linear-gradient(180deg, #000, #074069);
  flex-direction: column;
  align-items: center;
  padding-top: 1em;
}
.project-card .content h3 {
  margin-block: 0.5em 0;
  font-size: 24px;
  color: #fff;
  font-weight: bolder;
  letter-spacing: 1px;
}
.project-card .content p {
  margin-block: 0.5em 1em;
  text-align: center;
  width: 25ch;
  font-size: 20px;
}
@property --a {
  syntax: "<angle>";
  inherits: false;
  initial-value: 0deg;
}
@keyframes rotate {
  0% {
    --a: 0deg;
  }
  100% {
    --a: 360deg;
  }
}

@media (max-width: 70em) {
  .project-card {
    width: 77%;
    height: auto;
    margin-block: 0 10em;
  }
  .project-card:last-child {
    margin-block: 0;
  }
  .project-card:hover {
  }

  .project-card:hover::before,
  .project-card:hover::after {
  }

  .project-card img {
  }

  .project-card .content {
  }

  .project-card .content h3 {
    text-align: center;
    font-size: 20px;
  }

  .project-card .content p {
    font-size: 15px;
  }
}
