@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}
* {
  scroll-behavior: smooth;
  max-width: 100vw;
}
::-webkit-scrollbar {
  display: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.App {
  display: flex;
  flex-direction: column;
}
.App::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(#000, #0e73bc);
  z-index: 1;
}
header {
  height: 91vh;
  position: relative;
  padding-inline: 12em;
  margin-top: 9vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-image: url(./assets/HeaderIMG.webp);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 5;
}
header::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000078;
  z-index: 6;
}
header a {
  font-size: 14px;
}
header h3 {
  /* background: linear-gradient(90deg, #15a9c7, #83529c 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: #fff;
  font-weight: normal;
  text-shadow: -3px -1px 1px #83529c;
}
h1 {
  font-size: 48px;
  color: #fff;
  font-weight: bolder;
  margin-block: 0.3em;
  z-index: 10;
  font-family: "Poppins", sans-serif;
  max-width: 75%;
}
h2 {
  font-size: 40px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: #fff;
  text-shadow: -3px -2px 1px #83529c;
  text-transform: uppercase;
}
h3 {
  font-size: 32px;
  font-weight: bold;
  z-index: 10;
  margin-block: 0 2em;
  color: #15a9c7;
  font-family: "Poppins", sans-serif;
}
h5 {
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 24px;
  color: #fff;
  font-weight: 500;
  max-width: 10ch;
  font-family: "Poppins", sans-serif;
}
p {
  text-align: justify;
  max-width: 50ch;
  display: flex;
  align-items: center;
  font-size: 24px;
  color: #fff;
  line-height: 1.5em;
}
.section {
  height: 85vh;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding-inline: 5em;
}
.content {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.content img {
  width: 25em;
}
.services-box {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}
.service {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.icon-service {
  padding: 2em;
  position: relative;
}
.icon-service::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, #15a9c7, #83529c);
  border-radius: 50%;
  transition: 0.4s;
}
.icon-service:hover::before {
  transform: rotateZ(360deg);
}

.icon-service img {
  position: relative;
  z-index: 11;
}
.icon-service:hover img {
  animation: tilt-n-move-shaking 0.4s ease-in;
}
.kayotica-discription {
  background-image: url(./assets/kayoticaDES.webp);
  background-size: cover;
  background-repeat: no-repeat;
  width: 90%;
  height: 70vh;
  padding-inline: 10em;
  padding-block: 5em 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.kayotica-discription p {
  max-width: 44ch;
  margin-top: 5em;
  text-align: justify;
}
.projects-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  height: 78%;
}
.sponsorbox {
  display: flex !important;
  justify-content: space-evenly;
  width: 80%;
  height: 90%;
}
.sponsorbox img {
  width: 12.5em;
  transition: 2s;
}
.sponsorbox img:hover {
  transform: rotate(360deg) scale(1.2);
}
.head-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.team {
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
}

.member {
  height: 40vh;
  width: 40vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
}
.member img {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  transition: 0.6s;
}
.member .info {
  position: relative;
  z-index: 7;
  background: #000000a1;
  width: 100%;
  padding-inline: 5%;
  height: 0;
  transform: translateY(2em);
  padding-inline: 1em;
  transition: 0.5s;
  padding-block: 0.8em;
}

.member .info h3 {
  margin-block: 0 0;
  text-align: start;
  font-size: 18px;
}
.member .info .head {
  margin-block: 0;
  display: flex;
  justify-content: space-between;
}
.member .info .head h5 {
  font-size: 24px;
  font-weight: bolder;
  margin-block: 0;
  max-width: 80%;
}
.member .info .head a {
  width: 2em;
  height: 2em;
}
.member .info .head a svg {
  position: relative;
  width: 100%;
  height: 100%;
  fill: #fff;
  transition: 0.2s;
}
.member .info .head a:hover svg {
  fill: #1ac3e4;
}
.member:hover img {
  transform: scale(1.1);
}
.member:hover .info {
  height: 20%;
  transform: translateY(0em);
}
#trust {
  justify-content: space-around;
  height: 80vh;
  margin-block: 5em 7em;
}
@media (max-width: 70em) {
  header {
    background-size: 65em;
    background-position-x: -16em;
    padding: 3em 1em;

    justify-content: flex-start;
    height: auto;
  }

  h1 {
    font-size: 26px;
    max-width: 65%;
  }

  h2 {
    font-size: 22px;
    text-align: center;
  }
  h3 {
    font-size: 16px;
  }
  h4 {
  }
  h5 {
    font-size: 16px;
  }

  p {
    font-size: 13px;
    text-align: center;
  }
  header a {
    font-size: 10px;
  }
  .section {
    height: auto !important;
    padding-inline: 1em;
    margin-block: 2em;
  }
  .content {
    flex-direction: column-reverse;
    align-items: center;
  }
  .content img {
    width: 20em;
  }

  .services-box {
    flex-wrap: wrap;
    margin-top: 2em;
  }
  .service {
    margin-block: 1em;
    width: 35%;
  }

  .icon-service {
    padding: 1em;
  }
  .icon-service img {
    width: 2em;
  }
  .kayotica-discription {
    background-size: contain;
    background-position: center;
    height: auto;
    padding-block: 4em 1em;
  }

  .kayotica-discription p {
  }

  .projects-box {
    flex-direction: column;
    align-items: center;
    padding-block: 5em;
  }
  .sponsorbox {
    width: 100%;
    padding-block: 3em;
  }

  .sponsorbox img {
    width: 5.5em;
  }
  .head-section {
  }
  .team {
  }

  .member {
    height: 30vh;
    width: 30vh;
    margin-block: 2em;
  }
  .member img {
  }
  .member .info {
    height: 25%;
    padding-inline: 0.3em;
    flex-direction: column;
    justify-content: space-around;
    display: flex;
    transform: translateY(0);
  }

  .member .info h3 {
    font-size: 15px;
    padding-inline: 0.5em;
  }
  .member .info .head {
    height: 2em;
    align-items: center;
    padding-inline: 0.5em;
  }
  .member .info .head h5 {
    font-size: 20px;
  }
  .member .info .head a {
    height: 2em;
  }
  .member .info .head a svg {
    height: 130%;
  }
  .head-section h3 {
    text-align: center;
  }
}

@keyframes tilt-n-move-shaking {
  0% {
    transform: translate(0, 0) rotate(0deg) scale(1);
  }
  25% {
    transform: translate(5px, 5px) rotate(5deg) scale(1.4);
  }
  50% {
    transform: translate(0, 0) rotate(0deg) scale(1.4);
  }
  75% {
    transform: translate(-5px, 5px) rotate(-5deg) scale(1.4);
  }
  100% {
    transform: translate(0, 0) rotate(0deg) scale(1);
  }
}
