.navbar {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 9vh;
  position: fixed;
  width: 100vw;
  background: #000;
  z-index: 100;
  transition: 1s ease-out;
}
.scroll-navbar {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(7px);
  background: #00000090;
  box-shadow: 0 0 15px #000;
  transition: 1s ease-out;
}
.navbar img {
  width: 8em;
}
.navbar .button-gradiant {
  font-size: 12px;
}
.navbar ul {
  display: flex;
  justify-content: space-between;
  width: 30%;
  margin-inline-end: 15em;
}
.navbar ul li {
  list-style: none;
}
.navbar ul li a {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  transition: 0.4s;
}
.navbar ul li:hover a {
  color: #15a9c7;
  font-size: 24px;
}
@media (max-width: 70em) {
  .navbar {
    justify-content: space-between;
  }

  .scroll-navbar {
  }
  .navbar .button-gradiant {
    font-size: 10px;
  }
  .navbar img {
    margin-left: 1em;
  }

  .navbar ul {
    display: none;
  }

  .navbar ul li {
  }

  .navbar ul li a {
  }

  .navbar ul li:hover a {
  }
  .navbar a {
    margin-right: 1em;
  }
}
